import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, take } from 'rxjs';
import { LDFeatureManager } from '../shared/feature-management/ld-feature-manager';
import { FeatureFlags } from '../shared/models/enums/feature-flag.enum';

@Injectable()
export class AppIdInterceptor implements HttpInterceptor {
    private readonly appId = '7';
    private readonly excl = '/connect/token';

    constructor(private readonly featureManager: LDFeatureManager) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.featureManager.isEnabled(FeatureFlags.AddApplicationIdToRequestHeaders).pipe(
            take(1),
            mergeMap((result) => {
                if (request.url.search(this.excl) === -1 && result) {
                    return next.handle(
                        request.clone({
                            setHeaders: {
                                'aya-app-id': this.appId
                            }
                        })
                    );
                } else {
                    return next.handle(request);
                }
            })
        );
    }
}
