import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

@Component({
    templateUrl: './file-attachments-viewer.component.html',
    styleUrls: [ './file-attachments-viewer.component.scss' ]
})
export class FileAttachmentsViewerComponent extends UnsubscribeOnDestroy implements OnInit {
    attachmentPreview: any;
    upperButtonTitle: string;
    isLoading = false;
    isRendering = false;
    isPreviewLoading = false;
    isPreviewLoading$: Observable<boolean>;
    totalPages: number[];
    selectedPage = 1;

    @Output() onPageChange = new EventEmitter<any>();
    @Output() onDownload = new EventEmitter<any>();
    
    constructor(public readonly dialogRef: MatDialogRef<FileAttachmentsViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super();        
    }    

    ngOnInit() {
        if  (this.data){
            this.data.attachmentPreview$.pipe(takeUntil(this.d$)).subscribe(x => { 
                if (x && x.blob && x.totalPages > 0) {
                    this.isLoading = true;
                    this.createImageFromBlob(x.blob);
                    this.totalPages = Array(x.totalPages).fill(0).map((x,i) => i + 1);
                }
            });

            this.isPreviewLoading$ = this.data.isAttachmentPreviewLoading$;
        }
    }

    get title() {
        return this.data
            && this.data.file
            && this.data.file.fileName;
    }

    onNext() {
        if (this.selectedPage <  this.totalPages.length) {
            ++this.selectedPage;
            const page = this.selectedPage;
            this.getPage(page);
        }       
    }  

    onPrev() {
        if (this.selectedPage > 1) {
            --this.selectedPage
            const page = this.selectedPage;
            this.getPage(page);
        }
    }  

    onPageClicked(pageNumber: number){
        this.selectedPage = pageNumber;
        this.getPage(pageNumber);
    }
    onCancel() { 
        this.dialogRef.close();
    }

    onDownloadAttachment(){
        this.onDownload.emit(this.data.file);
        this.dialogRef.close();
    }  

    createImageFromBlob(blob: Blob) {   
        this.isRendering = true;     
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            this.attachmentPreview = reader.result;
            this.upperButtonTitle = "Download";
            this.isLoading = false;
            this.isRendering = false;
        }, false);

        reader.readAsDataURL(blob);
    }

    private getPage(currentPage: number) {        
        this.onPageChange.emit(currentPage);  
        this.isRendering = true;   
    }
}
