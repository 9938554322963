import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { withLatestFrom, takeUntil } from 'rxjs/operators';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';

import { ClinicalClient } from 'src/app/clinical/models';
import { ClinicalManager } from 'src/app/clinical/models/clinical-manager.model';
import { GridSearchQuery, GridStateChangeEvent } from 'src/app/shared/grid/models';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';

import * as selectors from 'src/app/clinical/store/selectors/client-grid.selectors';
import * as actions from 'src/app/clinical/store/actions/client-grid.actions';

@Component({
    selector: 'ayac-clients-grid',
    templateUrl: './clients-grid.component.html',
    styleUrls: ['./clients-grid.component.scss']
})
export class ClientsGridComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(GridComponent, { static: true }) grid: GridComponent;
    gridData$: Observable<GridDataResult>;
    gridQuery$: Observable<GridSearchQuery>;
    isLoading$: Observable<boolean>;

    clinicalManagersLookup$: Observable<ClinicalManager[]>;
    clinicalManagersFiltered$: BehaviorSubject<ClinicalManager[]> = new BehaviorSubject([]);

    selectedFacilities: ClinicalClient[] = [];
    selectedClinicalManager: ClinicalManager = null;

    form = new UntypedFormGroup({
        clinicalManagers: new UntypedFormControl(null),
        clinicalManagersFilter: new UntypedFormControl(null)
    });

    constructor(private readonly store: Store) {
        super();
    }

    get clinicalManagerControl(): AbstractControl | undefined {
        return this.form.get('clinicalManagers');
    }

    get clinicalManagerFilterControl(): AbstractControl | undefined {
        return this.form.get('clinicalManagersFilter');
    }

    ngOnInit() {
        this.gridQuery$ = this.store.select(selectors.selectClientsGridQuery).pipe(takeUntil(this.d$));
        this.gridData$ = this.store.select(selectors.selectClientsGridData).pipe(takeUntil(this.d$));
        this.isLoading$ = this.store.select(selectors.selectIsGridLoading).pipe(takeUntil(this.d$));

        this.isLoading$.subscribe((isLoading) => {
            if (!isLoading) {
                this.clearSelection();
            }
        });

        this.clinicalManagersLookup$ = this.store.select(selectors.selectClinicalManagers).pipe(takeUntil(this.d$));

        this.clinicalManagersLookup$.subscribe((cms: ClinicalManager[]) =>
            this.filterDropdown(cms, this.clinicalManagerFilterControl, this.clinicalManagersFiltered$)
        );

        this.clinicalManagerFilterControl.valueChanges
            .pipe(withLatestFrom(this.store.select(selectors.selectClinicalManagers)), takeUntil(this.d$))
            .subscribe(([, items]) =>
                this.filterDropdown(items, this.clinicalManagerFilterControl, this.clinicalManagersFiltered$)
            );

        this.clinicalManagerControl.valueChanges.pipe(takeUntil(this.d$)).subscribe((value) => {
            this.selectedClinicalManager = value;
        });

        this.store.dispatch(actions.loadComponent());
    }

    clearSelection() {
        this.form.controls.clinicalManagers.setValue(null);
        this.form.controls.clinicalManagersFilter.setValue(null);
        this.selectedFacilities = [];
    }

    onDataStateChange(state: GridStateChangeEvent) {
        this.store.dispatch(actions.setQuery({ query: state }));
    }

    saveClinicalManagerAssignment() {
        this.store.dispatch(
            actions.setClinicalManagers({
                clinicalManagerId: this.selectedClinicalManager.id,
                facilityIds: this.selectedFacilities.map((f) => f.hospId)
            })
        );
    }

    onRowSelected(rows: ClinicalClient[] = []): void {
        this.selectedFacilities = rows;
    }

    filterDropdown(items: ClinicalManager[], filterCtrl: AbstractControl, subject$: BehaviorSubject<any>): void {
        if (!items || items.length === 0 || !Array.isArray(items)) {
            return;
        }

        let search = filterCtrl?.value;
        if (!search) {
            const filteredItems = items?.slice();
            subject$.next(filteredItems);
            return;
        } else {
            search = search?.toLowerCase();
        }

        subject$.next(items?.filter((i) => i.fullName.toLowerCase().includes(search)));
    }
}
