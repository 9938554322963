import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { LookupsService } from 'src/app/lookups/services/lookups.service';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import { ListItem } from 'src/app/shared/models/list-item';
import { DateHelper, emailValidator } from 'src/app/shared/utilities';
import { Candidate, CandidateUpdate, WorkShift } from 'src/app/shared/models/candidate';
import { CandidateService } from 'src/app/shared/services/candidate.service';
import { VendorContact } from '../../models';
import { CountryStateGroup } from '../../models/address.model';
import { LDFeatureManager } from '../../feature-management/ld-feature-manager';
import { MatRadioChange } from '@angular/material/radio';
import { RequestTimeOffPipe } from '../../pipes/request-time-off.pipe';

@Component({
    selector: 'ayac-candidate-profile-form',
    templateUrl: './candidate-profile-form.component.html',
    styleUrls: ['./candidate-profile-form.component.scss'],
    providers: [RequestTimeOffPipe]
})
export class CandidateProfileFormComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    @Input() formGroupName = 'profile';
    @Input() vendorLabel = 'Vendor';
    @Input() firstNameLabel = 'First Name';
    @Input() middleNameLabel = 'Middle Name';
    @Input() lastNameLabel = 'Last Name';
    @Input() emailLabel = 'Email';
    @Input() contactLabel = 'Contact';
    @Input() preferredShiftLabel = 'Preferred Shift';
    @Input() ssnLabel = 'SSN';
    @Input() dateOfBirthLabel = 'DOB';
    @Input() availableStartDateLabel = 'Available to Start';
    @Input() address1Label = 'Address1';
    @Input() address2Label = 'Address2';
    @Input() cityLabel = 'City';
    @Input() stateLabel = 'State';
    @Input() zipCodeLabel = 'Zip';
    @Input() sellingPointsLabel = 'Selling Points';
    @Input() candidateId?: number;
    @Input() isAdminForm = false;
    @Input() vendorContacts: VendorContact[] = [];
    @Input() vendors: ListItem[] = [];
    @Input() isNew = false;
    @Input() otherInfo;
    @Input() rtoLabel = 'RTO';

    @Output() onChangeMade = new EventEmitter();
    @Output() onVendorChanged = new EventEmitter<number>();
    @Output() getSsnValue = new EventEmitter();
    rtoToggle = false;
    minDate: Date = new Date();
    candidateSavedRto = [];
    useLocalDateTimeForRtoAvailableDate = false;

    public get vendorIdControl(): AbstractControl | undefined {
        return this.form?.get('vendorId');
    }

    public get vendorFilterControl(): AbstractControl | undefined {
        return this.form?.get('vendorFilter');
    }

    public get firstNameControl(): AbstractControl | undefined {
        return this.form?.get('firstName');
    }

    public get middleNameControl(): AbstractControl | undefined {
        return this.form?.get('middleName');
    }

    public get lastNameControl(): AbstractControl | undefined {
        return this.form?.get('lastName');
    }

    public get emailControl(): AbstractControl | undefined {
        return this.form?.get('email');
    }

    public get contactControl(): AbstractControl | undefined {
        return this.form?.get('contact');
    }

    public get preferredShiftsControl(): AbstractControl | null {
        return this.form?.get('preferredShifts');
    }

    public get ssnControl(): AbstractControl | undefined {
        return this.form?.get('ssn');
    }

    public get dateOfBirthControl(): AbstractControl | undefined {
        return this.form?.get('dateOfBirth');
    }

    public get availableStartDateControl(): AbstractControl | undefined {
        return this.form?.get('availableStartDate');
    }

    public get address1Control(): AbstractControl | undefined {
        return this.form?.get('address1');
    }

    public get address2Control(): AbstractControl | undefined {
        return this.form?.get('address2');
    }

    public get cityControl(): AbstractControl | undefined {
        return this.form?.get('city');
    }

    public get stateControl(): AbstractControl | undefined {
        return this.form?.get('state');
    }

    public get zipCodeControl(): AbstractControl | undefined {
        return this.form?.get('zipCode');
    }

    public get sellingPointsControl(): AbstractControl | undefined {
        return this.form?.get('sellingPoints');
    }

    public get requestTimeOffDatesControl(): AbstractControl | undefined {
        return this.form?.get('requestTimeOffDates');
    }

    form: UntypedFormGroup;
    @Input() set additionalValidation(value: boolean) {
        if (value) {
            this.form.get('address1').setValidators(Validators.required);
            this.form.get('city').setValidators(Validators.required);
            this.form.get('state').setValidators(Validators.required);
            this.form.get('zipCode').setValidators(Validators.required);
        }
        this._additionalValidation = value;
    }

    get additionalValidation(): boolean {
        return this._additionalValidation;
    }

    filteredVendors: ReplaySubject<ListItem[]> = new ReplaySubject<ListItem[]>();

    dataPopulated = false;

    shiftTypes$: Observable<WorkShift[]>;
    countryStatesLookup$: Observable<CountryStateGroup[]>;
    candidate?: Candidate;
    selectedVendorContact?: VendorContact;
    ssnEditable = false;
    showSsn = false;
    readonly featureFlag = FeatureFlag;

    private _additionalValidation: boolean;

    requestTimeOffDatesFormat = (date: Date[], viewValue: string) => {
        return this.requestTimeOffDateFormat.transform(date, 'MM/DD/YYYY', this.useLocalDateTimeForRtoAvailableDate);
    };

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly formGroupDirective: FormGroupDirective,
        private readonly candidateService: CandidateService,
        private readonly lookupService: LookupsService,
        private readonly _ldFeatureManager: LDFeatureManager,
        private readonly requestTimeOffDateFormat: RequestTimeOffPipe
    ) {
        super();
        this.form = this.formBuilder.group({
            vendorId: this.formBuilder.control(null),
            vendorFilter: this.formBuilder.control(null),
            firstName: this.formBuilder.control(null, Validators.required),
            middleName: this.formBuilder.control(null),
            lastName: this.formBuilder.control(null, Validators.required),
            email: this.formBuilder.control(null, [Validators.required, emailValidator]),
            contact: this.formBuilder.control(null, [Validators.required]),
            preferredShifts: this.formBuilder.control([]),
            ssn: this.formBuilder.control(null, Validators.pattern('^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$')),
            dateOfBirth: this.formBuilder.control(null),
            availableStartDate: this.formBuilder.control(null),
            address1: this.formBuilder.control(null),
            address2: this.formBuilder.control(null, Validators.maxLength(50)),
            city: this.formBuilder.control(null),
            state: this.formBuilder.control(null),
            zipCode: this.formBuilder.control(null, Validators.maxLength(50)),
            sellingPoints: this.formBuilder.control(null, Validators.maxLength(1500)),
            requestTimeOffDates: this.formBuilder.control([]),
            rto: this.formBuilder.control(false)
        });

        if (this.isAdminForm) {
            this.form.get('vendorId').setValidators(Validators.required);
            this.form.get('availableStartDate').setValidators(Validators.required);
        }
    }

    ngOnInit(): void {
        this._ldFeatureManager
            .isEnabled(FeatureFlag.UseLocalDateTimeForVendorCandidateSubmittalRTOAvailableDate)
            .pipe(takeUntil(this.d$))
            .subscribe((isEnabled: boolean) => {
                this.useLocalDateTimeForRtoAvailableDate = isEnabled;
            });

        const stateLookup$ = this.isAdminForm
            ? this.lookupService.getStates(null, true)
            : this.lookupService.getStates();
        this.countryStatesLookup$ = stateLookup$.pipe(
            map((states) => {
                const countryStateGroups: CountryStateGroup[] = [];
                states.forEach((state) => {
                    const countryGroup = countryStateGroups.find((x) => x.country.name === state.country.name);
                    if (countryGroup) {
                        countryGroup.states.push(state);
                        return;
                    }

                    countryStateGroups.push({ country: state.country, states: [state] });
                });
                return countryStateGroups;
            }),
            takeUntil(this.d$)
        );

        this.shiftTypes$ = this.candidateService.getShiftTypes();

        this.formGroupDirective.form.addControl(this.formGroupName, this.form);
        this.form.setParent(this.formGroupDirective.form);

        this.form.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            if (!this.dataPopulated) {
                return;
            }
            this.onChangeMade.emit();
        });

        this.contactControl.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => {
            this.refreshSelectedVendorContact();
        });

        this.vendorIdControl.valueChanges.pipe(takeUntil(this.d$)).subscribe((vendorId) => {
            if (!this.dataPopulated) {
                return;
            }
            this.onVendorChanged.emit(vendorId);
            this.vendorContacts = [];
            this.contactControl.setValue(null);

            // Only trigger validation if not a new candidate
            // This seemed to be the only way to trigger the validation after setting value
            // if a contact was already set
            if (!this.isNew) {
                this.contactControl.markAsTouched();
            }

            this.refreshSelectedVendorContact();
        });

        if (this.isAdminForm) {
            this.address1Control.clearValidators();
        }

        this.vendorFilterControl.valueChanges.pipe(takeUntil(this.d$)).subscribe(() => this.filterVendors());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vendorContacts?.currentValue) {
            this.refreshSelectedVendorContact();
        }
        if (changes.vendors?.currentValue) {
            this.filterVendors();
        }
        if (changes.otherInfo?.currentValue) {
            this.updateOtherInfo(changes.otherInfo.currentValue);
        }
    }

    populate(candidate: Candidate): void {
        this.candidate = candidate;

        this.vendorIdControl.setValue(candidate?.vendorId);
        this.address1Control.setValue(candidate?.address1);
        this.address2Control.setValue(candidate?.address2);
        this.cityControl.setValue(candidate?.city);
        this.stateControl.setValue(candidate?.stateId);
        this.zipCodeControl.setValue(candidate?.zipCode);

        this.firstNameControl.setValue(candidate?.firstName);
        this.middleNameControl.setValue(candidate?.middleName);
        this.lastNameControl.setValue(candidate?.lastName);
        this.emailControl.setValue(candidate?.email);
        this.contactControl.setValue(candidate?.vendorContactId);
        this.preferredShiftsControl?.setValue(candidate?.preferredShiftTypes);
        this.dateOfBirthControl.setValue(candidate?.dob ? new Date(candidate.dob) : null);
        this.availableStartDateControl.setValue(
            candidate?.availableStartDate ? new Date(candidate.availableStartDate) : null
        );
        this.sellingPointsControl.setValue(candidate?.sellingPoints);

        this.requestTimeOffDatesControl.setValue(candidate?.requestTimeOffDates);
        this.rtoToggle =
            candidate !== null ? candidate?.requestTimeOffDates && candidate.requestTimeOffDates.length > 0 : false;
        this.form.get('rto').setValue(this.rtoToggle);

        this.dataPopulated = true;
    }

    getCandidate(): Candidate {
        const candidate: Candidate = JSON.parse(JSON.stringify(this.candidate ?? {}));
        candidate.id = this.candidateId ? this.candidateId : null;
        candidate.vendorId = this.vendorIdControl.value;
        candidate.firstName = this.firstNameControl.value;
        candidate.middleName = this.middleNameControl.value;
        candidate.lastName = this.lastNameControl.value;
        candidate.email = this.emailControl.value;
        candidate.vendorContactId = this.contactControl.value;
        candidate.preferredShiftTypes = this.preferredShiftsControl?.value ?? [];
        candidate.includeSSN = this.candidate?.includeSSN ?? true;
        candidate.dob = this.dateOfBirthControl.value;
        candidate.availableStartDate =
            this.useLocalDateTimeForRtoAvailableDate && this.availableStartDateControl.value
                ? DateHelper.localDate(this.availableStartDateControl.value)
                : this.availableStartDateControl.value;
        candidate.sellingPoints = this.sellingPointsControl.value;
        candidate.address1 = this.address1Control.value;
        candidate.address2 = this.address2Control.value;
        candidate.city = this.cityControl.value;
        candidate.stateId = this.stateControl.value;
        candidate.zipCode = this.zipCodeControl.value;

        candidate.requestTimeOffDates = this.useLocalDateTimeForRtoAvailableDate
            ? this.requestTimeOffDatesControl.value?.map((rto) => DateHelper.localDate(rto))
            : this.requestTimeOffDatesControl.value;
        if (candidate?.requestTimeOffDates && candidate.requestTimeOffDates.length > 0) {
            this.candidateSavedRto = candidate.requestTimeOffDates;
        }

        return candidate;
    }

    getCandidateForUpdate(): CandidateUpdate {
        const candidate = this.getCandidate();
        return { ...candidate, ssn: this.ssnControl.value };
    }

    filterVendors(): void {
        if (!this.vendors) {
            return;
        }

        let search = this.vendorFilterControl.value;
        if (!search) {
            this.filteredVendors.next(this.vendors.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredVendors.next(this.vendors.filter((vendor) => vendor.name.toLowerCase().indexOf(search) > -1));
    }

    editSsn() {
        this.ssnEditable = !this.ssnEditable;
        this.getSsnValue.emit();
    }

    toggleShowSsn() {
        this.showSsn = !this.showSsn;
    }

    updateOtherInfo(otherInfo) {
        this.ssnControl.setValue(otherInfo?.ssn);
        this.candidate.includeSSN = true;
    }

    rtoToggleChange(event: MatRadioChange) {
        this.rtoToggle = event.value;

        if (!this.rtoToggle) {
            this.requestTimeOffDatesControl.clearValidators();
            this.requestTimeOffDatesControl.updateValueAndValidity();
            this.requestTimeOffDatesControl.setValue([]);
        } else {
            if (this.candidateSavedRto) {
                this.requestTimeOffDatesControl.setValue(this.candidateSavedRto);
            }
            this.requestTimeOffDatesControl.setValidators(Validators.required);
            this.requestTimeOffDatesControl.updateValueAndValidity();
        }
    }

    clearStoredRto() {
        this.candidateSavedRto = [];
    }

    private refreshSelectedVendorContact(): void {
        this.selectedVendorContact = this.vendorContacts?.find((x) => x.id === this.contactControl.value);
    }
}
