import {
    Component,
    AfterViewInit,
    Input,
    ElementRef,
    ViewChild,
    ChangeDetectorRef,
    Output,
    EventEmitter
} from '@angular/core';
import { IConfirmationDialogOptions } from 'src/app/shared/models/dialog.models';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FilesToUpload, FileValidationResult, AttachmentConfig } from 'src/app/shared/models/attachment';
import { Subject } from 'rxjs';
import { ToasterService } from 'src/app/core/services';

export interface AttachmentFile {
    attachmentId: number;
    file: File;
}

@Component({
    selector: 'ayac-draganddrop-uploader',
    templateUrl: './drag-and-drop-uploader.component.html',
    styleUrls: ['./drag-and-drop-uploader.component.scss']
})
export class DragAndDropUploaderComponent extends UnsubscribeOnDestroy implements AfterViewInit {
    document: any = { files: new Array<FilesToUpload>() };
    validationResults: FileValidationResult[] = [];
    fileExtensions: string;
    defaultAllowedFileSize = 50;

    /**
     * Intended as an all at once setter, not to be used to incrementally set individual files.
     */
    @Input() set files(val: FileList | Array<{ attachmentId: number; fileName: string }>) {
        let newFiles: FilesToUpload;
        const attachmentIds: number[] = [];
        const dataTransfer = new DataTransfer();
        // This is to clear any existing list when this setter is used.
        if (this.document) {
            this.document.files.length = 0;
        }
        if (val instanceof FileList) {
            // Convert FileList to an array of { name: string } objects
            this.addFiles(val, undefined, false);
        } else if (Array.isArray(val)) {
            // Convert the array of { fileName: string } to an array of { name: string }
            val.forEach((item) => {
                attachmentIds.push(item.attachmentId);
                const file = new File([''], item.fileName);
                dataTransfer.items.add(file);
            });
            newFiles = dataTransfer.files;
            this.addFiles(newFiles, attachmentIds, false);
        }
    }

    @Input()
    fileUploaderConfig = new AttachmentConfig();

    @Input()
    editMode = true;

    @Input() loading: boolean;

    @Output() hasFileAttached = new EventEmitter<File[]>();

    @Output() attachmentClicked = new EventEmitter<AttachmentFile>();

    @ViewChild('fileInput') fileInput: ElementRef;

    private attachments: AttachmentFile[] = [];
    private readonly filesChangedSubject$: Subject<boolean> = new Subject();
    public filesChanged$ = this.filesChangedSubject$.asObservable();

    constructor(
        private readonly dialog: DialogService,
        private readonly toasterService: ToasterService,
        private readonly ref: ChangeDetectorRef
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.fileExtensions = this.fileUploaderConfig.validFileExtensions.join(', ');
        this.ref.detectChanges(); //seems to throw a console error if it detects any changes
    }

    onFileClicked(index: number): void {
        if (this.attachments?.length > 0) {
            this.attachmentClicked.emit(this.attachments[index]);
        }
    }

    //triggered when a user drags/drops a file
    getDroppedFiles(e: DragEvent): void {
        this.updateDragState(e);
        this.addFiles(e.dataTransfer.files);
    }

    //triggered when a user clicks the "browse" button
    openExplorer(): void {
        this.fileInput.nativeElement.click();
    }

    addSelectedFiles(): void {
        this.addFiles(this.fileInput.nativeElement.files);
        this.fileInput.nativeElement.value = null;
    }

    updateDragState(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }
        this.fileUploaderConfig.isDragging = e.type === 'dragover';
    }

    //User clicks the trash can to remove a file.
    removeFile(value: File): void {
        const dialogOptions: IConfirmationDialogOptions = {
            data: {
                title: this.fileUploaderConfig.deleteDialogTitle,
                text: this.fileUploaderConfig.deleteDialogText,
                cancelButtonText: 'No, cancel',
                confirmButtonText: 'Yes, delete',
                isDeleteConfirmation: true,
                isWarn: true
            }
        };

        this.dialog.openConfirmationDialog(dialogOptions).then((result) => {
            if (result) {
                this.document.files = this.document.files.filter((file) => file.name !== value.name);
                this.filesChangedSubject$.next(true);
                this.hasFileAttached.emit(this.getFiles());
            }
        });
    }

    getFiles(): File[] {
        if (this.document.files && this.document.files.length) {
            return this.document.files;
        }
        return [];
    }

    private addFiles(fileList: FileList, attachmentIds: number[] | undefined = undefined, emit = true): void {
        this.validationResults = [];
        //make sure only one file is being uploaded at a time.
        if (fileList.length > 1) {
            this.validationResults.push({
                isValid: false,
                name: '',
                message: 'Multiple files are not allowed. Please upload only a single file.'
            });
        } else {
            this.attachments = [];
            for (let i = 0; i < fileList.length; i++) {
                const file: any = fileList[i];
                if (this.validateFile(fileList[i])) {
                    this.document.files.pop();
                    this.document.files.push(file);
                    this.attachments.push({ attachmentId: attachmentIds ? attachmentIds[i] : undefined, file });
                    this.document.originalFileName = this.document.files.length > 1 ? 'Multiple files' : file.name;
                    if (emit) {
                        this.hasFileAttached.emit(this.document.files);
                    }
                }
            }
        }
        if (this.fileUploaderConfig.isVendorExpenseDragAndDrop) {
            this.publishValidationMessage();
        }
        this.filesChangedSubject$.next(true);
    }

    private publishValidationMessage() {
        const messages: string[] = [];
        this.validationResults.forEach((v) => {
            if (!v.isValid) {
                messages.push(v.message);
            }
        });

        if (messages.length > 0) {
            const combinedMessage = messages.join('\n');
            this.toasterService.fail(combinedMessage);
        }
    }

    private validateFile(file: File): boolean {
        if (this.fileUploaderConfig.isVendorExpenseDragAndDrop) {
            this.defaultAllowedFileSize = 20;
        }
        if (file.size > this.defaultAllowedFileSize * 1024 * 1024) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `File format exceeds ${this.defaultAllowedFileSize}MB size limit. Please save the file as a smaller size and try again.`
            });
        }

        if (this.document.files.find((f) => f.name === file.name)) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `There is already a file named ${file.name}. Please save the file with a different file name and try again.`
            });
        }

        const ext = /(?:\.([^.]+))?$/.exec(file.name)[1];
        if (!this.fileUploaderConfig.validFileExtensions.some((x) => x === `.${ext}`.toLowerCase())) {
            this.validationResults.push({
                isValid: false,
                name: file.name,
                message: `File type not supported. Supported file types are ${this.fileUploaderConfig.validFileExtensions.join(
                    ', '
                )} .`
            });
        }
        return this.validationResults.length <= 0;
    }
}
