import { Component, Inject, OnInit } from '@angular/core';
import { Editor, Validators } from 'ngx-editor';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'ayac-submittal-status-update-dialog',
    templateUrl: './submittal-status-update-dialog.component.html',
    styleUrls: ['./submittal-status-update-dialog.component.scss']
})
export class SubmittalStatusUpdateDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    editor!: Editor;
    maxCharacterLength = 2000;
    editorForm: FormGroup = new FormGroup({
        note: new FormControl('', [Validators.maxLength(this.maxCharacterLength)])
    });

    constructor(
        public readonly dialogRef: MatDialogRef<SubmittalStatusUpdateDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { title: string; description: string; confirmButtonText: string }
    ) {
        super();
    }

    ngOnInit(): void {
        this.editor = new Editor();
    }

    updateSubmittalStatus(confirm: boolean) {
        this.dialogRef.close({
            updateStatus: confirm,
            note: this.editorForm.get('note').value
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.editor?.destroy();
    }
}
