export enum ConnexusFeatureFlags {
    VendorCandidateDobValidation = 'VENDOR_CANDIDATE_DOB_VALIDATION',
    ManageFeaturesContractedDateCol = 'MANAGE_FEATURES_CONTRACTED_DATE_COL',
    RemoveVendorHelpPrivacyPolicy = 'REMOVE_VENDOR_HELP_PRIVACY_POLICY',
    DisplayPreferredShiftInVendorSubmittals = 'display-preferred-shift-in-vendor-submittals',
    DisplaySubmittalLevelPreferredShiftsAndAvailableDateInSubmittalsDetails = 'display-submittal-level-preferred-shifts-and-available-date-in-submittals-details',
    AvoidRecreatingPhoneRecordsDuringVendorCandidateUpdate = 'avoid-recreating-phone-records-during-vendor-candidate-update',
    SkipUnnecessaryRequestsNewAdminVendorCandidate = 'SkipUnnecessaryRequestsNewAdminVendorCandidate',
    DisplayRTOClientSubmittals = 'DisplayRTOClientSubmittals',
    RetainSubmittalIdUponSave = 'submittal-retain-id-upon-save',
    UseJobStartDateAsCandidateAvailableDate = 'UseJobStartDateAsCandidateAvailableDate',
    SubmittalsCandidateInfoLoader = 'Submittals-Candidate-Info-Loader',
    ExistingVendorSubmittalEditRTO = 'EXISTING_SUBMITTAL_EDIT_RTO',
    UseAyaConnectToCallUnitDescriptionApis = 'UseAyaConnectToCallUnitDescriptionApis',
    UpdateCandidateCallEndpointsRelatedToUpdatedSections = 'update-candidate-call-endpoints-related-to-updated-sections',
    AdminCallEndpointsRelatedToCandidateUpdatedSections = 'admin-call-endpoints-related-to-candidate-updated-sections',
    UseLocalDateTimeForVendorCandidateSubmittalRTOAvailableDate = 'use-local-datetime-for-vendor-candidate-submittal-rto-available-date',
    SkipDetailFilesRequestNewAdminVendorCandidate = 'skip-detail-files-request-new-admin-vendor-candidate',
    AddAgentNameAndEmailForVmsSubmittals = 'add-agent-name-and-email-for-vms-submittals',
    LazyLoadVendorJobRequirementDescriptions = 'lazy-load-vendor-job-requirement-descriptions',
    SetVendorCandidateNpiFromFormControl = 'set-vendor-candidate-npi-from-form-control',
    AlwaysUseFacilityOrientationHoursForVendorJobDetails = 'always-use-facility-orientation-hours-for-vendor-job-details',
    RestrictCandidatesToMatchJobProfession = 'restrict-candidates-to-match-job-profession',
    ClientSubmittalDetailsMoveActivitySection = 'client-submittal-details-move-activity-section',
    AddContractGroupToVendorsJobs = 'add-contract-group-to-vendors-jobs',
    LazyLoadVendorCandidateContractRequirements = 'lazy-load-vendor-contract-requirements',

    // Start SergiiM
    VendorJobDetailsCaMinimumWageType = 'vendor-job-details-ca-minimum-wage-type',
    VendorSubmittalsDetailsMoveStatusToTopRight = 'vendor-submittals-details-move-status-to-top-right'
    // End SergiiM
}
