import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubmittalsState, submittalsStoreKey } from './submittals.state';
import { LoadingTypes } from 'src/app/shared/models';
import { SubmittalStatuses } from 'src/app/shared/models/submittals';
import { GroupItem } from 'src/app/shared/grid/models';

export const selectFeature = createFeatureSelector<SubmittalsState>(submittalsStoreKey);

/// Submittals ///

export const selectSubmittals = createSelector(selectFeature, (state) => state.list);

export const getIsSubmittalsListLoading = createSelector(selectFeature, (state) =>
    [state.list.loading, state.lookups.loadingSecurity, state.lookups.loadingStatuses].some(
        (x) => x === LoadingTypes.LOADING
    )
);

export const getSubmittalsList = createSelector(selectSubmittals, (state) => ({
    data: state.submittals,
    total: state.submittalsTotal
}));

/// Submittal Details ///

export const selectSubmittalDetails = createSelector(selectFeature, (state) => state.details);

export const getIsSubmittalDetailsLoading = createSelector(selectFeature, (state) =>
    [
        state.details.statusLoading,
        state.details.detailsLoading,
        state.details.emailDataLoading,
        state.details.jobsLoading,
        state.offer.submittalOfferRescinding,
        state.offer.submittalOfferDetailsLoading,
        state.lookups.loadingSecurity,
        state.lookups.loadingStatuses
    ].some((x) => x === LoadingTypes.LOADING)
);

export const getSubmittalDetails = createSelector(selectSubmittalDetails, (state) => state.submittalDetails);

export const getSubmittalDetailsCanUpdateStatus = createSelector(
    selectSubmittalDetails,
    (state) => state.canUpdateStatus
);

export const getSubmittalMspCustomFields = createSelector(selectSubmittalDetails, (state) => state.mspCustomFields);

export const getSubmittalJobs = createSelector(selectSubmittalDetails, (state) => state.submittalJobs);

export const getSubmittalEmailData = createSelector(selectSubmittalDetails, (state) => state.emailData);

export const selectIsPendingOverdue = createSelector(selectSubmittalDetails, (state) => state.isPendingOverdue);

/// Lookups ///

export const selectSubmittalLookups = createSelector(selectFeature, (state) => state.lookups);

export const getSubmittalLookupsFacilities = createSelector(selectSubmittalLookups, (state) => state.facilities);

export const getSubmittalLookupsSystems = createSelector(selectSubmittalLookups, (state) => state.systems);

export const getSubmittalLookupsUnits = createSelector(selectSubmittalLookups, (state) => state.units);

export const getSubmittalLookupsStatuses = createSelector(selectSubmittalLookups, (state) => state.statuses);

export const selectMappedSubmittalStatuses = createSelector(selectSubmittalLookups, (state) => state.mappedStatuses);
export const selectMappedSubmittalStatusesV2 = createSelector(selectSubmittalLookups, (state) =>
    state.mappedStatuses.filter((s) => s.id !== SubmittalStatuses.MoreInformationNeeded)
);

export const selectCurrentSubmittalStatus = createSelector(selectSubmittalLookups, (state) => state.currentStatus);

export const selectSubmittalDetailsDropdownStatuses = createSelector(
    selectSubmittalLookups,
    (state) => state.submittalDetailsDropdownStatuses
);

export const selectStatusBadge = createSelector(selectSubmittalLookups, (state) => state.statusBadge);

export const selectSubmittalDeclineReasons = createSelector(selectSubmittalLookups, (state) => state.declineReasons);

/// Submittals Query ///

export const selectSubmittalQuery = createSelector(selectFeature, (state) => state.query);

/// Submittals Application ///

export const selectSubmittalApplication = createSelector(selectFeature, (state) => state.application);

export const getIsSubmittalApplicationLoading = createSelector(selectSubmittalApplication, (state) =>
    state.loading.some((x) => x === LoadingTypes.LOADING)
);

export const getSubmittalApplicationsLoadingIds = createSelector(selectSubmittalApplication, (state) =>
    state.loading.map((e, i) => (e === LoadingTypes.LOADING ? i : -1)).filter((x) => x >= 0)
);

/// Attachments ///
export const selectSubmittalAttachmentsMetaData = createSelector(
    selectFeature,
    (state) => state.details.attachmentsMetaData
);

export const selectSubmittalAttachmentsBlob = createSelector(
    selectFeature,
    (state) => state.details.submittalAttachmentBlob
);

export const selectCandidateEducation = createSelector(selectFeature, (state) => state.details.candidateEducation);

export const selectCandidateWorkHistory = createSelector(selectFeature, (state) => state.details.candidateWorkHistory);

export const selectCandidateQualifications = createSelector(
    selectFeature,
    (state) => state.details.candidateQualifications
);

export const selectSubmittalLookupsStatusesInPlacedState = createSelector(selectSubmittalLookups, (state) =>
    state.statuses.filter((x) => x.id !== SubmittalStatuses.Placed && x.id !== SubmittalStatuses.MoreInformationNeeded)
);

export const selectSubmittalDiversityInfo = createSelector(selectFeature, (state) => state.list.submittalInfo);

export const selectSubmittalActivities = createSelector(selectFeature, (state) => state.details.activities);

export const selectIrpStatuses = createSelector(selectSubmittalLookups, (state) => state.irpStatuses);

export const selectIrpStatusesDropDown = createSelector(
    selectSubmittalLookups,
    (state) => state.irpSubmittalDetailsDropdownStatuses
);

export const selectStatusAnSubmittalStatusLookup = createSelector(selectSubmittalLookups, (state) => {
    const items: GroupItem[] = [];

    items.push({
        name: 'statusIds',
        displayName: 'IRP',
        items: state.irpStatuses.map((t) => {
            return { id: t.id, name: t.name };
        })
    });

    items.push({
        name: 'submittalStatusId',
        displayName: 'Non-IRP',
        items: state.mappedStatuses.map((t) => {
            return { id: t.id, name: t.name };
        })
    });

    return items;
});
