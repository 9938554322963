import { LoadingTypes } from 'src/app/shared/models';
import { ClinicalClientGridState } from '../state/client-grid.state';
import { createReducer, on } from '@ngrx/store';
import {
    loadClinicalManagersFailure,
    loadClinicalManagersSuccess,
    loadGrid,
    loadGridFailure,
    loadGridSuccess,
    setClinicalManagers,
    setClinicalManagersFailure,
    setClinicalManagersSuccess,
    setQuery
} from '../actions/client-grid.actions';

export const initialClientGridState = (): ClinicalClientGridState => ({
    loading: LoadingTypes.INIT,
    error: undefined,
    setClinicalManagerRequest: undefined,
    query: {
        filter: {
            logic: 'and',
            filters: [{ field: 'contractStatus', operator: 'equals', value: 'Signed' }]
        },
        skip: 0,
        take: 25,
        sort: [{ field: 'hospitalName', dir: 'asc' }]
    },
    data: [],
    total: 0,
    clinicalManagers: []
});

export const clientGridReducer = createReducer(
    initialClientGridState(),

    on(setQuery, (state, action) => ({
        ...state,
        query: action.query
    })),

    on(loadClinicalManagersSuccess, (state, action) => ({
        ...state,
        clinicalManagers: action.clinicalManagers
    })),

    on(loadClinicalManagersFailure, (state, action) => ({
        ...state,
        error: action.error
    })),

    on(loadGrid, (state) => ({
        ...state,
        loading: LoadingTypes.LOADING
    })),

    on(loadGridSuccess, (state, action) => ({
        ...state,
        data: action.data,
        total: action.total,
        loading: LoadingTypes.LOADED
    })),

    on(loadGridFailure, (state, action) => ({
        ...state,
        error: action.error,
        loading: LoadingTypes.FAIL
    })),

    on(setClinicalManagers, (state, action) => ({
        ...state,
        setClinicalManagerRequest: { clinicalManagerId: action.clinicalManagerId, facilityIds: action.facilityIds },
        loading: LoadingTypes.LOADING
    })),

    on(setClinicalManagersSuccess, (state) => ({
        ...state,
        setClinicalManagerRequest: null
    })),

    on(setClinicalManagersFailure, (state) => ({
        ...state,
        loading: LoadingTypes.LOADED
    }))
);
