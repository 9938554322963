import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { PendingChangesComponent } from 'src/app/shared/guards/pending-changes.guard';
import { DomainConfigurationKeys } from 'src/app/shared/models';
import { ListItem } from 'src/app/shared/models/list-item';
import { VendorSubmittalDetails } from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DomainService } from 'src/app/shared/services/domain.service';
import * as selectors from '../../store/selectors/submittals-detail.selectors';
import * as actions from '../../store/actions/submittals-detail.actions';
import { getSelectorValue } from '../../store/selectors/admin.selectors';
import { IConfirmationDialogOptions, IDialogOptions } from 'src/app/shared/models/dialog.models';
import { map, takeUntil } from 'rxjs/operators';
import { RejectOfferDialogComponent } from 'src/app/shared/components/reject-offer-dialog/reject-offer-dialog.component';
import { SharedSubmittalDetailsComponent } from 'src/app/shared/components/submittal-details/submittal-details.component';

@Component({
    selector: 'ayac-submittals-admin-detail',
    templateUrl: './submittals-admin-details.component.html',
    styleUrls: ['./submittals-admin-details.component.scss']
})
export class SubmittalsAdminDetailsComponent extends UnsubscribeOnDestroy implements OnInit, PendingChangesComponent {
    @ViewChild(SharedSubmittalDetailsComponent) detailsComponent: SharedSubmittalDetailsComponent;

    submittalId: number;
    candidateId: number;
    jobId: number;
    accountManagerId: number;
    stageReasons: ListItem[];
    isLoading$: Observable<boolean>;
    details$: Observable<VendorSubmittalDetails>;
    submittalStages$: Observable<ListItem[]>;
    hasChanges$: Observable<boolean>;
    isSaving$: Observable<boolean>;
    canSave$: Observable<boolean>;
    submittalUpdatedNote = '';
    noteLabel = '';

    constructor(
        private readonly _store: Store,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _domain: DomainService,
        private readonly _dialogService: DialogService
    ) {
        super();
    }

    ngOnInit(): void {
        this.isLoading$ = this._store.select(selectors.selectSubmittalsDetailIsLoading);
        this.details$ = this._store.select(selectors.selectSubmittalsDetail);
        this.submittalStages$ = this._store.select(selectors.selectAvailableSubmittalStages);
        this.hasChanges$ = this._store.select(selectors.selectHasChanges);
        this.isSaving$ = this._store.select(selectors.selectIsSavingDetails);
        this.canSave$ = combineLatest([this.hasChanges$, this.isSaving$]).pipe(
            map(([hasChanges, isSaving]) => !isSaving && hasChanges)
        );
        this._store
            .select(selectors.selectSubmittalStageReasons)
            .pipe(takeUntil(this.d$))
            .subscribe((reasons) => (this.stageReasons = reasons));

        this.submittalId = Number(this._route.snapshot.paramMap.get('id'));
        this._store.dispatch(actions.loadSubmittalsDetails({ submittalId: this.submittalId }));
        this._store.dispatch(actions.loadSubmittalStages());
        this._store.dispatch(actions.loadSubmittalStageReasons());

        this.noteLabel = this._domain.getValue(DomainConfigurationKeys.NOTE_LABEL);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this._store.dispatch(actions.clearSubmittalDetails());
    }

    incomingChanges(event: any): void {
        this._store.dispatch(actions.setChangeState({ hasChanges: event?.hasChanges }));

        if (event.hasChanges) {
            this.submittalUpdatedNote = event?.note;
        }
    }

    save(): void {
        this._store.dispatch(
            actions.updateSubmittalNote({ note: this.submittalUpdatedNote, submittalId: this.submittalId })
        );
    }

    updateStage(stage: ListItem): void {
        if (!stage) {
            return;
        }

        switch (stage.name?.toLowerCase()) {
            case 'cancelled':
                this.confirmStageUpdate(
                    stage.id,
                    'Cancel Submittal',
                    'Are you sure you want to cancel this submittal? Please provide a reason for the cancelation: '
                );
                break;
            case 'candidate rejected':
                this.confirmStageUpdate(
                    stage.id,
                    'Candidate Rejected',
                    'Are you sure you want to reject this candidate? Please provide a reason for the rejection: '
                );
                break;
            case 'offer declined':
                this.confirmStageUpdate(
                    stage.id,
                    'Offer Declined',
                    'Are you sure you want to decline this offer? Please provide a reason: '
                );
                break;
            default:
                this.dispatchStageUpdate(stage.id, null, null);
                break;
        }
    }

    goToJobDetails(jobId: number): void {
        this._router.navigate(['/admin', 'job', jobId]);
    }

    goToCandidateDetails(candidateId: number): void {
        this._router.navigate(['/admin', 'vendorCandidate', candidateId]);
    }

    goToVendorDetails(vendorId: number): void {
        this._router.navigate(['/admin', 'vendor', vendorId]);
    }

    canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
        return getSelectorValue(this.hasChanges$).then((hasChanges) => {
            return hasChanges ? this.promptPageChangeDialog() : true;
        });
    }

    private promptPageChangeDialog(): Promise<boolean> {
        const options: IConfirmationDialogOptions = {
            data: {
                title: 'Submittal Details',
                text: 'You have unsaved changes! Do you want to leave?',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            }
        };

        return this._dialogService.openConfirmationDialog(options);
    }

    private confirmStageUpdate(stageId: number, title: string, text: string): void {
        const options: IDialogOptions<any> = {
            width: '40%',
            data: {
                title,
                text,
                confirmButtonText: 'Save',
                cancelButtonText: 'Close',
                reasons: this.stageReasons,
                note: ''
            }
        };
        const dialogRef = this._dialogService.openDialog(RejectOfferDialogComponent, options);

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result.isConfirmed) {
                    this.dispatchStageUpdate(stageId, result?.formData?.note, result?.formData?.reason);
                } else {
                    this.detailsComponent.clearStageSelection();
                }
            });
    }

    private dispatchStageUpdate(stageId: number, note: string, submittalStageReasonId: number): void {
        this._store.dispatch(
            actions.updateStageHistoryEntry({
                entry: {
                    currentStageId: stageId,
                    submittalId: this.submittalId,
                    note,
                    submittalStageReasonId,
                    submittalStatusId: null
                }
            })
        );
    }
}
