import { ConnexusFeatureFlags } from './connexus-feature-flags.enum';
import { StarBucksFeatureFlags } from './starbucks-feature-flags.enum';
import { AyaWannaConnectFeatureFlags } from './aya-wanna-connect-feature-flags.enum';
import { ALatteFunFeatureFlags } from './a-latte-fun-feature-flags';
import { AyaKiddingMeFeatureFlags } from './aya-kidding-me-feature-flags.enum';
import { AMIRightFeatureFlags } from './am-i-right-feature-flags';
import { ProgramManagementFeatureFlags } from './program-management-feature-flags';
import { SyncOrSwimFlags } from './sync-or-swim-flags.enum';
import { QuesoFormaggioFeatureFlags } from './queso-formaggio-feature-flags.enum';
import { XAlchemistsFeatureFlags } from 'src/app/shared/models/enums/x-alchemists-feature-flags';
import { ShiftsGettingRealFeatureFlags } from 'src/app/shared/models/enums/shifts-getting-real-feature-flags.enum';
import { AccountOperationsFeatureFlags } from './account-operations-feature-flags';
import { ItsOver9000FeatureFlags } from './its-over-9000-feature-flags';
import { HWFeatureFlags } from './hw-feature-flags.enum';

export enum FeatureFlags {
    // Note: leave a trailing comma,
    // it will help avoid conflicts in merge
    FeatureA = 'FeatureA',
    FeatureB = 'FeatureB',
    AddIRPRecordType = 'ADD_IRP_RECORD_TYPE',
    UpdateSelectAllForGetShifts = '20220711_SHIFTS-1970_UPDATE_SELECTALL_GETSHIFTS',
    OrientationDetailsFix = '20220808_CNW-11933_ORIENTAION_DETAILS_FIX',
    DevOpsFlag = 'DEVOPS_FLAG',
    ShowIsOnHoldFieldSubmittalsGrid = 'SHOW_IS_ON_HOLD_FIELD_SUBMITTALS_GRID',
    UseCoreEndpointsForClinicalClientsGrid = 'USE_CORE_ENDPOINTS_FOR_CLINICAL_CLIENTS_GRID',
    ConnectClientJobApprovalsForExtensionJobs = 'CONNECT_CLIENT_JOB_APPROVALS_FOR_EXTENSION_JOBS',
    EnableAccessToVendorToShiftsApp = 'ENABLE_ACCESS_TO_VENDOR_TO_SHIFTS_APP',
    RefreshCustomFieldsOnFacilityChange = 'REFRESH_CUSTOM_FIELDS_ON_FACILITY_CHANGE',
    AddApplicationIdToRequestHeaders = 'add-application-id-to-request-headers',
    EnableJobTemplateCustomFields = 'ENABLE_JOB_TEMPLATE_CUSTOM_FIELDS',
    EnableJobTemplateCustomFieldsSync = 'ENABLE_JOB_TEMPLATE_CUSTOM_FIELDS_SYNC',
    EnablePerDiemWorkerCustomFields = 'ENABLE_PER_DIEM_WORKER_CUSTOM_FIELDS',
    SystemSettingsWarningPopupForDisableShiftsTiering = 'connect-system-settings-warning-popup-for-disable-shifts-tiering',
    AddSystemColumnAtContractGroupInManageFeaturePageSection = 'ADD_SYSTEM_COLUMN_AT_CONTRACT_GROUP_IN_MANAGE_FEATURES_PAGE_SECTION',
    OnlyCancelBulkShiftsInTheFuture = 'ONLY_CANCEL_BULK_SHIFTS_IN_THE_FUTURE',
    ShiftTieringReleaseAtShiftStartTime = 'SHIFT_TIERING_RELEASE_AT_SHIFT_START_TIME',
    ShowNativeToolTipForAgencyInShiftSettingsPage = 'SHOW_NATIVE_TOOLTIP_FOR_AGENCY_IN_SHIFT_SETTINGS_PAGE',
    ShiftSeriesCancelAllOrNothing = 'SHIFT_SERIES_CANCEL_ALL_OR_NOTHING',
    WfdHideUnitDescriptionRestrictedFields = 'WFD-2486_CONNECT_HIDE_UNIT_DESCRIPTION_RESTRICTED_FIELDS',
    AccessSecureUDLinks = 'ACCESS_SECURE_UD_LINKS',
    AccessSecureUdLinksByFacilities = 'access-secure-ud-links',
    AddFacilityFilterToIrpWorkerPage = 'ADD_FACILITY_FILTER_TO_IRP_WORKER_PAGE',
    AllowReOpenOfShiftSeriesOnceCancelled = 'ALLOW_RE_OPENING_OF_SHIFT_SERIES_ONCE_CANCELLED',
    PreventSavingShiftSeriestInOverlapSituation = 'PREVENT_SAVING_SHIFT_SERIES_IN_OVERLAP_SITUATION',
    CancelModalProfessionPopulatedFix = 'connect-cancel-modal-profession-populated-fix',
    ShiftsScheduleCalendarGridLarger = 'connect-shifts-schedule-calendar-grid-larger',
    EnableDashboardTodoTileConnectExpenses = 'ENABLE-DASHBOARD-TODO-TILE-CONNECT-EXPENSES',
    ScheduleDetailsManageButton = 'connect-schedule-details-manage-button',
    InvoiceByCaregiverByHosptalSystemUpdate = 'invoice-by-caregiver-by-hospital-system-update',
    ClientConnectVmsDocDueDate = 'client-connect-vms-doc-due-date',
    Auth0UseIdentityEmailHint = 'auth0-use-identity-email-hint',
}

export const FeatureFlag = {
    ...FeatureFlags,
    ...ConnexusFeatureFlags,
    ...StarBucksFeatureFlags,
    ...AyaWannaConnectFeatureFlags,
    ...ALatteFunFeatureFlags,
    ...AyaKiddingMeFeatureFlags,
    ...ItsOver9000FeatureFlags,
    ...AMIRightFeatureFlags,
    ...ProgramManagementFeatureFlags,
    ...SyncOrSwimFlags,
    //...SvtFeatureFlags,
    ...QuesoFormaggioFeatureFlags,
    ...ShiftsGettingRealFeatureFlags,
    ...XAlchemistsFeatureFlags,
    ...AccountOperationsFeatureFlags,
    ...HWFeatureFlags
};

export type FeatureFlag =
    | FeatureFlags
    | ConnexusFeatureFlags
    | StarBucksFeatureFlags
    | AyaWannaConnectFeatureFlags
    | ALatteFunFeatureFlags
    | AyaKiddingMeFeatureFlags
    | ItsOver9000FeatureFlags
    | AMIRightFeatureFlags
    | ProgramManagementFeatureFlags
    | SyncOrSwimFlags
    //| SvtFeatureFlags
    | QuesoFormaggioFeatureFlags
    | ShiftsGettingRealFeatureFlags
    | XAlchemistsFeatureFlags
    | AccountOperationsFeatureFlags
    | HWFeatureFlags;
