import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, Settings } from 'src/app/config/settings';
import { interpolateUrl } from 'src/app/core/utils';
import { GridLookup } from '../grid/models';

@Injectable({
    providedIn: 'root'
})
export class ExpensesService {
    constructor(private readonly http: HttpClient, @Inject(APP_CONFIG) private readonly settings: Settings) {}

    getExpenseAttachment(attachmentId: number): Observable<Blob> {
        const urlParams = {
            attachmentId
        };
        const url = interpolateUrl('/AyaConnect/expenses/{attachmentId}/attachment/', urlParams);
        return this.http.get(`${this.settings.CORE}${url}`, {
            responseType: 'blob'
        });
    }

    getExpenseTypes(): Observable<GridLookup[]> {
        const url = `${this.settings.CORE}/AyaConnect/expenses/expense-types`;
        return this.http.get<GridLookup[]>(url);
    }

    getExpenseStatuses(): Observable<GridLookup[]> {
        const url = `${this.settings.CORE}/AyaConnect/expenses/expense-statuses`;
        return this.http.get<GridLookup[]>(url);
    }
}
