/** Angular */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
/** NgRx */
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, filter, tap, takeUntil } from 'rxjs/operators';
/** Kendo */
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
/** Services, models, components */
import { FacilityLookup } from 'src/app/shared/models/submittals/lookups/facility-lookup.model';
import { SubmittalJobOpenReason } from 'src/app/shared/models/submittals/enums/submittal-jobopenreason';
import { SubmittalStatusLookup } from 'src/app/shared/models/submittals/lookups/submittal-status-lookup.model';
import { GridSearchQuery, GridStateChangeEvent, GroupItem, GroupSelection } from 'src/app/shared/grid/models';
import { UnitLookup } from 'src/app/shared/models/submittals/lookups/unit-lookup.model';
import { SubmittalListItem } from 'src/app/shared/models/submittals/submittal-list-item.model';
import { SubmittalStatusMappedLookup } from 'src/app/shared/models/submittals/lookups/submittal-status-mapped-lookup.model';
import {
    SubmittalStatusType,
    SubmittalStatuses
} from 'src/app/shared/models/submittals/enums/submittal-status-type.enum';
import { GridComponent } from 'src/app/shared/grid/components/grid.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { UnsubscribeOnDestroy } from 'src/app/core/utils';
import { ListItem } from 'src/app/shared/models/list-item';
import { getSubmittalsLinesOfBusiness } from 'src/app/lookups/state/lookups.selectors';
import { loadSubmittalsLinesOfBusiness } from 'src/app/lookups/state/actions';
import { SubmittalAuthService } from 'src/app/submittals/services/submittal-auth.service';
import * as fromSubmittals from 'src/app/submittals/store/submittals.selectors';
import * as submittalsActions from 'src/app/submittals/store/actions';
import { FeatureFlag } from 'src/app/shared/models/enums/feature-flag.enum';
import {
    SubmittalDiversityCertification,
    SubmittalDiversityInfo
} from 'src/app/shared/models/submittals/diversity-info.model';
import { IsPendingReviewOverDue, OverDueBy } from 'src/app/shared/utilities/is-pending-review-over-due';
import { LDFeatureManager } from 'src/app/shared/feature-management/ld-feature-manager';
import { CandidateOfferStatus } from 'src/app/shared/models/offers/enums/candidate-offer-status.enum';
import { SubmittalDetailsRoutesHelperService } from 'src/app/submittals/components/submittal-details/submittal-details-routes-helper-service';

@Component({
    selector: 'ayac-submittals',
    templateUrl: './submittals.component.html',
    styleUrls: ['./submittals.component.scss']
})
export class SubmittalsComponent extends UnsubscribeOnDestroy implements OnInit {
    @ViewChild(GridComponent, { static: true }) grid: GridComponent;

    PAGE_ID = 'client-submittals-page';
    lastSelectedLineOfBusiness: any;

    submittals$: Observable<GridDataResult>;
    submittalsData: GridDataResult;
    submittalsQuery$: Observable<GridSearchQuery>;
    facilityLookups$: Observable<FacilityLookup[]>;
    statusLookups$: Observable<SubmittalStatusLookup[]>;
    statusLookupsInPlacedState$: Observable<SubmittalStatusLookup[]>;
    mappedStatuses$: Observable<SubmittalStatusMappedLookup[]>;
    linesOfBusiness$: Observable<ListItem[]>;
    statusAndSubStatusLookup$: Observable<GroupItem[]>;

    unitLookupsFiltered$: Observable<UnitLookup[]>;

    isLoading$: Observable<boolean>;
    isApplicationLoading$: Observable<boolean>;

    facilityFilterValue$: Observable<number>;
    facilityFilterDefaultText$: Observable<string>;
    isFacilityFilterEnabled$: Observable<boolean>;
    isFacilitySelected$: Observable<boolean>;
    unitFilterTooltip$: Observable<string>;

    selected = [];

    currentStatus$: Observable<SubmittalStatusMappedLookup>;

    isPendingOverdue$: Observable<boolean>;
    featureFlags = FeatureFlag;
    subStatuses = [];
    submittalDiversityInfo: SubmittalDiversityInfo[] = [];

    statusAndSubmittalStatusSubject$ = new BehaviorSubject<GroupSelection[]>([]);
    statusAndSubmittalStatus$ = this.statusAndSubmittalStatusSubject$.asObservable();
    selectedStatusAndSubmittalStatuses: GroupSelection[];

    isStandaloneVmsFlagEnabled = false;
    submittalRoutes = SubmittalDetailsRoutesHelperService;

    isConnectClientMoreInformationNeededEnabled = false;
    isConnectClientDisplayNewSubmittalStatusEnabled = false;

    constructor(
        private readonly _store: Store<{}>,
        private readonly _commonService: CommonService,
        private readonly _router: Router,
        private readonly _submittalAuth: SubmittalAuthService,
        private readonly _ldFeatureManager: LDFeatureManager
    ) {
        super();
    }

    get isConnectDetailIRPJobSubmittals() {
        return this._submittalAuth.hasConnectDetailIRPJobSubmittals();
    }

    ngOnInit() {
        const isConnectDetailIRPJobSubmittals = this.isConnectDetailIRPJobSubmittals;
        this._store.dispatch(submittalsActions.loadSubmittalsLookupsSecurity());
        this._store.dispatch(submittalsActions.loadSubmittalsLookupsStatuses());
        this._store.dispatch(submittalsActions.loadSubmittals({ isConnectDetailIRPJobSubmittals }));
        this._store.dispatch(loadSubmittalsLinesOfBusiness());
        // trick to hide Facility dropdown from old modules
        this._commonService.hidePageLayoutElement('FACILITY_DROPDOWN', 'client-submittals-page');

        this.isLoading$ = this._store.select(fromSubmittals.getIsSubmittalsListLoading);
        this.isApplicationLoading$ = this._store.select(fromSubmittals.getIsSubmittalApplicationLoading);
        this.submittals$ = this._store.select(fromSubmittals.getSubmittalsList).pipe(
            map((response) => {
                response.data.forEach((submittal: SubmittalListItem) => {
                    const checkPendingReview =
                        submittal.statusId === SubmittalStatusType.PENDING_REVIEW ||
                        submittal.submittalStatusId === SubmittalStatuses.PendingReview;
                    submittal.isPendingOver2Days = IsPendingReviewOverDue(
                        checkPendingReview,
                        submittal.submissionSentOn,
                        OverDueBy
                    );
                });
                this.submittalsData = response;
                this.mapSubmittalVendorInfoToSubmittals();
                return response;
            })
        );

        this.statusAndSubStatusLookup$ = this._store.select(fromSubmittals.selectStatusAnSubmittalStatusLookup);
        this.facilityLookups$ = this._store.select(fromSubmittals.getSubmittalLookupsFacilities);
        this.statusLookups$ = this._store.select(fromSubmittals.getSubmittalLookupsStatuses);
        this.statusLookupsInPlacedState$ = this._store.select(
            fromSubmittals.selectSubmittalLookupsStatusesInPlacedState
        );
        this.linesOfBusiness$ = this._store.select(getSubmittalsLinesOfBusiness);

        this._ldFeatureManager
            .isEnabled(this.featureFlags.ConnectClientMoreInformationNeededEnabled)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.isConnectClientMoreInformationNeededEnabled = flagIsEnabled;
            });
        this._ldFeatureManager
            .isEnabled(this.featureFlags.ConnectClientDisplayNewSubmittalStatus)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.isConnectClientDisplayNewSubmittalStatusEnabled = flagIsEnabled;
            });

        if (this.isConnectClientMoreInformationNeededEnabled && this.isConnectClientDisplayNewSubmittalStatusEnabled) {
            this.mappedStatuses$ = this._store.select(fromSubmittals.selectMappedSubmittalStatusesV2);
        } else {
            this.mappedStatuses$ = this._store.select(fromSubmittals.selectMappedSubmittalStatuses);
        }

        this.currentStatus$ = this._store.select(fromSubmittals.selectCurrentSubmittalStatus);

        this.isPendingOverdue$ = this._store.select(fromSubmittals.selectIsPendingOverdue);

        this.getVendorInfoForSubmittals();
        this.initVendorInfoSubmittals();

        this.submittalsQuery$ = combineLatest([
            this.facilityLookups$,
            this._store.select(fromSubmittals.selectSubmittalQuery),
            this.statusLookups$,
            this.statusAndSubmittalStatus$
        ]).pipe(
            tap(([fl, query, statuses]) => {
                const lineOfBusinessFilter: any = query.filter?.filters.find(
                    (x: any) => x.field === 'lineOfBusinessId'
                );

                this._querySubmittalStatus(query, statuses);

                const value = lineOfBusinessFilter?.value || -1;
                if (this.lastSelectedLineOfBusiness !== value) {
                    this.clearGridSelection();
                    this.lastSelectedLineOfBusiness = value;
                }
            }),
            filter(([fl, query]) => fl !== null && query.filter !== null),
            map(([fl, query]) => {
                if (fl && fl.length === 1 && query !== null && query.filter !== null) {
                    const facilityFilter = query.filter.filters.find(
                        (x) => (x as FilterDescriptor).field === 'searchFacilityIds'
                    );
                    if (!facilityFilter) {
                        query.filter.filters.push({
                            field: 'searchFacilityIds',
                            operator: 'eq',
                            value: [fl[0].id]
                        });
                        this._store.dispatch(submittalsActions.setSubmittalsSearchQuery({ query }));
                    }
                }

                return query;
            })
        );

        this.isFacilityFilterEnabled$ = this.facilityLookups$.pipe(
            map((lookups) => lookups !== null && lookups.length > 1)
        );

        this.facilityFilterValue$ = this.submittalsQuery$.pipe(
            filter((query) => query.filter !== null),
            map((query) => {
                const filters = query.filter.filters as FilterDescriptor[];
                const facilityIdFilter = filters.find((f) => f.field === 'searchFacilityIds');
                return facilityIdFilter ? facilityIdFilter.value : null;
            })
        );

        this.isFacilitySelected$ = this.facilityFilterValue$.pipe(map((filterValue) => filterValue !== null));
        this.unitFilterTooltip$ = this.isFacilitySelected$.pipe(
            map((isFacilitySelected) => (isFacilitySelected ? 'Please select facility first' : null))
        );

        this.facilityFilterDefaultText$ = combineLatest([this.facilityLookups$, this.facilityFilterValue$]).pipe(
            map(([fl, fv]) => (fl !== null && fl.length === 1 && fv !== null ? fl[0].name : 'All facilities'))
        );

        this.unitLookupsFiltered$ = combineLatest([
            this.submittalsQuery$,
            this._store.select(fromSubmittals.getSubmittalLookupsUnits)
        ]).pipe(
            map(([query, units]) => {
                const facilityFilter = query.filter.filters.find(
                    (x) => (x as FilterDescriptor).field === 'searchFacilityIds'
                );
                const unitFilter = query.filter.filters.find((x) => (x as FilterDescriptor).field === 'unitId');
                if (facilityFilter) {
                    const searchFacilityIds = ((facilityFilter as FilterDescriptor).value as number[]) ?? [];
                    const filteredUnits = units.filter((u) => searchFacilityIds.includes(u.facilityId));
                    if (unitFilter) {
                        const unitId = (unitFilter as FilterDescriptor).value;
                        const findUnit = filteredUnits.find((u) => u.id === unitId);
                        if (!findUnit) {
                            query.filter.filters = query.filter.filters.filter(
                                (x) => (x as FilterDescriptor).field !== 'unitId'
                            );
                            this._store.dispatch(submittalsActions.setSubmittalsSearchQuery({ query }));
                        }
                    }

                    return filteredUnits;
                } else {
                    if (unitFilter) {
                        query.filter.filters = query.filter.filters.filter(
                            (x) => (x as FilterDescriptor).field !== 'unitId'
                        );
                        this._store.dispatch(submittalsActions.setSubmittalsSearchQuery({ query }));
                    }

                    return [];
                }
            })
        );

        this._ldFeatureManager
            .isEnabled(this.featureFlags.ConnectStandaloneVMSClientOfferFlow)
            .pipe(takeUntil(this.d$))
            .subscribe((flagIsEnabled) => {
                this.isStandaloneVmsFlagEnabled = flagIsEnabled;
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this._store.dispatch(submittalsActions.clearSubmittals());
    }

    onDataStateChange(state: GridStateChangeEvent) {
        const isConnectDetailIRPJobSubmittals = this.isConnectDetailIRPJobSubmittals;
        this._store.dispatch(submittalsActions.setSubmittalsSearchQuery({ query: state }));
        this._store.dispatch(submittalsActions.loadSubmittals({ isConnectDetailIRPJobSubmittals }));
    }

    onRowSelected(rows) {
        this.selected = rows;
    }

    export() {
        this._store.dispatch(
            submittalsActions.exportSubmittals({
                submittalIds: this.selected.map((s) => s.id),
                isConnectDetailIRPJobSubmittals: this.isConnectDetailIRPJobSubmittals
            })
        );
    }

    downloadApplication(submittalId: number) {
        this._store.dispatch(submittalsActions.downloadSubmittalsApplication({ submittalId }));
    }

    isSubmittalApplicationLoading(submittalId: number): Observable<boolean> {
        return this._store
            .select(fromSubmittals.getSubmittalApplicationsLoadingIds)
            .pipe(map((x) => x.includes(submittalId)));
    }

    isExtensionsJob(jobOpenReasonId: number, statusId: number): boolean {
        return jobOpenReasonId === SubmittalJobOpenReason.Extension && statusId === SubmittalStatusType.OFFER;
    }

    isExtensionsJobV2(jobOpenReasonId: number, statusId: number): boolean {
        return jobOpenReasonId === SubmittalJobOpenReason.Extension && statusId === SubmittalStatuses.Offer;
    }

    hasOfferInChangeRequestStatus(item: SubmittalListItem) {
        return item.offerStatusId === CandidateOfferStatus.ChangesRequested;
    }

    onViewApplication(item: SubmittalListItem) {
        if (this.isStandaloneVmsFlagEnabled) {
            const { candidateId, travelerName: candidateName, id, hasOffer } = item;

            if (candidateId) {
                this._router.navigate(['client', 'shiftssubmittals', 'detail', candidateId, 'documents'], {
                    queryParams: { candidateName }
                });
            } else if (hasOffer) {
                this._router.navigate(['client', 'submittals', id, SubmittalDetailsRoutesHelperService.offerDetails]);
            } else {
                this._router.navigate(['client', 'submittals', id]);
            }
        } else {
            const { candidateId, travelerName: candidateName, id } = item;

            if (candidateId) {
                this._router.navigate(['client', 'shiftssubmittals', 'detail', candidateId, 'documents'], {
                    queryParams: { candidateName }
                });
            } else {
                this._router.navigate(['client', 'submittals', id]);
            }
        }
    }

    clearGridSelection(): void {
        this.grid.clearSelection();
    }

    getVendorInfoForSubmittals() {
        this._store.dispatch(submittalsActions.loadSubmittalDiversityInfo());
    }

    initVendorInfoSubmittals() {
        this._store
            .select(fromSubmittals.selectSubmittalDiversityInfo)
            .pipe(takeUntil(this.d$))
            .subscribe((result) => {
                if (result.length > 0) {
                    this.submittalDiversityInfo = result;
                }
            });
    }

    mapSubmittalVendorInfoToSubmittals() {
        if (this.submittalDiversityInfo?.length > 0) {
            this.submittalsData.data?.forEach((submittal) => {
                submittal.certifications = this.mapSubmittalVendorInfoToSubmittal(submittal.id);
            });
        }
    }

    mapSubmittalVendorInfoToSubmittal(id: number): SubmittalDiversityCertification[] {
        const vendorInfo = this.submittalDiversityInfo.find((vendor) => vendor.submittalId === id);
        return vendorInfo ? vendorInfo.certifications : [];
    }

    onStatusesChange({ value }: { value?: GroupSelection[] }): void {
        this.selectedStatusAndSubmittalStatuses = this.statusAndSubmittalStatusSubject$.value;
        this.statusAndSubmittalStatusSubject$.next(value);
    }

    private _querySubmittalStatus(query: GridSearchQuery, statuses: SubmittalStatusLookup[]): void {
        if (!query?.filter?.filters || query?.filter?.filters.length === 0) {
            return;
        }

        this.subStatuses = [];
        const submittalStatus: any = query.filter?.filters.find((x: any) => x.field === 'submittalStatusId');
        const statusIds: any = query.filter?.filters.find((x: any) => x.field === 'statusIds');
        const statusValue = submittalStatus?.value;

        if (statusValue?.length === 1 && (!statusIds || statusIds?.value?.length === 0)) {
            const statusId = statusValue[0];
            const foundStatus = statuses.find((status: SubmittalStatusLookup) => status.id === statusId);
            if (foundStatus?.subStatuses?.length) {
                this.subStatuses = foundStatus.subStatuses;
            }
        } else {
            query.filter.filters = query.filter?.filters.filter((x: any) => x.field !== 'submittalSubStatusId');
        }
    }
}
