import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { SubmittalRejectDialogData } from 'src/app/shared/models/submittals-vendor/submittals-vendor-details.model';

@Component({
    selector: 'reject-offer-dialog-dialog',
    templateUrl: './reject-offer-dialog.component.html',
    styleUrls: ['./reject-offer-dialog.component.scss']
})
export class RejectOfferDialogComponent extends ConfirmationDialogComponent implements OnInit {
    form: UntypedFormGroup;
    constructor(
        public readonly dialogRef: MatDialogRef<RejectOfferDialogComponent>,
        private readonly formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) readonly data: SubmittalRejectDialogData
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            reason: null,
            note: ''
        });
    }

    close(isConfirmed: boolean = false): void {
        const formData = isConfirmed ? this.form.value : null;

        const data = {
            isConfirmed: isConfirmed,
            formData: formData
        };
        this.dialogRef.close(data);
    }
}
