import { createAction, props } from '@ngrx/store';
import { GridStateChangeEvent } from 'src/app/shared/grid/models';
import { ClinicalClient } from '../../models';
import { ClinicalManager } from '../../models/clinical-manager.model';

export const setQuery = createAction('[CLINICAL CLIENT API] Set Query', props<{ query: GridStateChangeEvent }>());

export const loadGrid = createAction('[CLINICAL CLIENT API] Load Grid');

export const loadGridSuccess = createAction(
    '[CLINICAL CLIENT API] Load Grid Success',
    props<{ data: ClinicalClient[]; total: number }>()
);

export const loadGridFailure = createAction('[CLINICAL CLIENT API] Load Grid Failure', props<{ error: unknown }>());

export const loadComponent = createAction('[CLINICAL CLIENT API] Load Component');

export const loadClinicalManagers = createAction('[CLINICAL CLIENT API] Load Clinical Managers');

export const loadClinicalManagersSuccess = createAction(
    '[CLINICAL CLIENT API] Load Clinical Managers success',
    props<{ clinicalManagers: ClinicalManager[] }>()
);

export const loadClinicalManagersFailure = createAction(
    '[CLINICAL CLIENT API] Load Clinical Managers failure',
    props<{ error: unknown }>()
);

export const setClinicalManagers = createAction(
    '[CLINICAL CLIENT API] Set Clinical Managers',
    props<{ clinicalManagerId: number; facilityIds: number[] }>()
);

export const setClinicalManagersSuccess = createAction('[CLINICAL CLIENT API] Set Clinical Managers success');

export const setClinicalManagersFailure = createAction(
    '[CLINICAL CLIENT API] Set Clinical Managers failure',
    props<{ error: unknown }>()
);
