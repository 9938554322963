import { Injectable } from '@angular/core';
import { SecurityPermissionEnum } from 'src/app/jobs/enums/job-security-enum';
import { IdentityService } from './identity.service';

export enum PermissionModules {
    CLIENT = 'client',
    ADMIN = 'admin',
    JOB_APPROVAL = 'jobapprovalprocesses',
    VENDOR = 'vendor'
}

export enum ClientPermissions {
    TRAVELERS_DETAIL_DOCUMENTS = 'travelers_Detail_Documents',
    TRAVELERS_DETAIL_DOCUMENTS_DECLINE_APPROVE = 'travelers_Detail_Documents_Decline_Approve',
    TRAVELERS_CONFIRM_REQUIREMENTS = 'travelers_Confirm_Requirements',
    CLIENT_CANDIDATE_EVALUATION_INFORMATION = 'clientCandidateEvaluationInformation',
    //#region Bill Rate
    BILL_RATE_VIEW = 'bill_rate_view',
    BILL_RATE_EDIT = 'bill_rate_edit',
    //#endregion
    TRAVELERS_INTERNALID_EDIT = 'travelers_internalid_edit',
    FACILITY_MANAGEMENT_EDIT = 'facility_management_edit',
    //#region Custom Fields
    CUSTOM_FIELDS_VIEW = 'custom_fields_client_view',
    CUSTOM_FIELDS_EDIT = 'custom_fields_client_edit',
    //#endregion
    //#region Jobs
    JOB_TEMPLATES = 'jobstemplates',
    JOB_EDIT = 'jobs_Edit',
    JOB_ADD = 'jobs_Add',
    JOB_DETAIL = 'jobs_Detail',
    JOBS_IMPORT = 'jobs_Import',
    //#endregion
    //#region Invoices
    BILLABLE_INVOICES_DETAIL = 'billable_invoices_Detail',
    INVOICED_DETAIL = 'invoiced_Detail',
    TIMECARD_BILLING_DETAILS = 'timecards_BillingDetails',
    SUBMITTAL_OFFER = 'submittals_offers'
}

export enum AdminPermissions {
    ADMIN_CUSTOM_FIELDS_VIEW = 'custom_fields_admin_view',
    ADMIN_CUSTOM_FIELDS_EDIT = 'custom_fields_admin_edit'
}

export enum JobApprovalPermissions {
    JOB_APPROVAL_PROCESS_VIEW = 'view'
}

export enum VendorPermissions {
    EXPENSES = 'vendor_expenses'
}

export enum ClientPermissions {
    EXPENSES = 'client_expenses'
}

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    constructor(private readonly _identityService: IdentityService) {}

    jobApprovalProcess(): boolean {
        return this.GetJobApprovalPermission(JobApprovalPermissions.JOB_APPROVAL_PROCESS_VIEW);
    }

    jobDetails(): boolean {
        return this.GetClientPermission(ClientPermissions.JOB_DETAIL);
    }

    jobTemplates(): boolean {
        return this.GetClientPermission(ClientPermissions.JOB_TEMPLATES);
    }

    jobEdit(): boolean {
        return this.GetClientPermission(ClientPermissions.JOB_EDIT);
    }

    jobAdd(): boolean {
        return this.GetClientPermission(ClientPermissions.JOB_ADD);
    }

    jobsImport(): boolean {
        return this.GetClientPermission(ClientPermissions.JOBS_IMPORT);
    }

    travelersConfirmRequirements(): boolean {
        return this.GetClientPermission(ClientPermissions.TRAVELERS_CONFIRM_REQUIREMENTS);
    }

    travelersDetailsDocuments(): boolean {
        return this.GetClientPermission(ClientPermissions.TRAVELERS_DETAIL_DOCUMENTS);
    }

    travelersDetailsDocumentsDeclineApprove(): boolean {
        return this.GetClientPermission(ClientPermissions.TRAVELERS_DETAIL_DOCUMENTS_DECLINE_APPROVE);
    }

    candidateEvaluationInformation(): boolean {
        return this.GetClientPermission(ClientPermissions.CLIENT_CANDIDATE_EVALUATION_INFORMATION);
    }

    billRateView(): boolean {
        return this.GetClientPermission(ClientPermissions.BILL_RATE_VIEW);
    }

    billRateEdit(): boolean {
        return this.GetClientPermission(ClientPermissions.BILL_RATE_EDIT);
    }

    travelersInternalIdEdit(): boolean {
        return this.GetClientPermission(ClientPermissions.TRAVELERS_INTERNALID_EDIT);
    }

    facilityManagementEdit(): boolean {
        return this.GetClientPermission(ClientPermissions.FACILITY_MANAGEMENT_EDIT);
    }

    customFieldsView(): boolean {
        return this.GetClientPermission(ClientPermissions.CUSTOM_FIELDS_VIEW);
    }

    customFieldsEdit(): boolean {
        return this.GetClientPermission(ClientPermissions.CUSTOM_FIELDS_EDIT);
    }

    customFieldsViewOrEdit(): boolean {
        return (
            this.GetClientPermission(ClientPermissions.CUSTOM_FIELDS_VIEW) ||
            this.GetClientPermission(ClientPermissions.CUSTOM_FIELDS_EDIT)
        );
    }
    invoicedDetailView(): boolean {
        return this.GetClientPermission(ClientPermissions.INVOICED_DETAIL);
    }

    billableInvoiceDetailView(): boolean {
        return this.GetClientPermission(ClientPermissions.BILLABLE_INVOICES_DETAIL);
    }

    timecardInvoicedDetailView(): boolean {
        return this.GetClientPermission(ClientPermissions.INVOICED_DETAIL);
    }

    timecardBillableInvoiceDetailView(): boolean {
        return this.GetClientPermission(ClientPermissions.BILLABLE_INVOICES_DETAIL);
    }

    timecardBillingDetailView(): boolean {
        return this.GetClientPermission(ClientPermissions.TIMECARD_BILLING_DETAILS);
    }

    customFieldsAdmin(type: 'view' | 'edit'): boolean {
        return this.GetAdminPermission(
            type === 'edit' ? AdminPermissions.ADMIN_CUSTOM_FIELDS_EDIT : AdminPermissions.ADMIN_CUSTOM_FIELDS_VIEW
        );
    }

    hasWFDContributorPermission(): boolean {
        return this._identityService.hasSecurityPermission(SecurityPermissionEnum.JobAddWorkforceDisruption);
    }

    hasJobMarketInsightsPermission() {
        return this.GetClientPermission('job_market_insights_view');
    }

    vendorExpenses(): boolean {
        return this.GetVendorPermission(VendorPermissions.EXPENSES);
    }

    /* Client Expenses Permissions*/
    clientExpenses(): boolean {
        return this.GetClientPermission(ClientPermissions.EXPENSES);
    }

    submittalOffer(): boolean {
        return this.GetClientPermission(ClientPermissions.SUBMITTAL_OFFER);
    }

    private GetClientPermission(name: string): boolean {
        return this._identityService.inScope(PermissionModules.CLIENT, name);
    }

    private GetAdminPermission(name: string): boolean {
        return this._identityService.inScope(PermissionModules.ADMIN, name);
    }

    private GetJobApprovalPermission(name: string): boolean {
        return this._identityService.inScope(PermissionModules.JOB_APPROVAL, name);
    }

    private GetVendorPermission(name: string): boolean {
        return this._identityService.inScope(PermissionModules.VENDOR, name);
    }
}
